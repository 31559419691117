<template>
  <div>
    <NavbarCommon :title="title" :back="back"></NavbarCommon>
    <div class="title">{{ title }}</div>
    <br />
    <div class="Fbuttons">
      <input
        type="button"
        value="重新开始"
        class="restart"
        @click="restartInit()"
      />
    </div>
    <div class="box">
      <canvas
        ref="canvas"
        :width="canvasW"
        :height="canvasH"
        @click="chessClick"
      ></canvas>
      <div class="win">{{ victory }}</div>
    </div>
  </div>
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
export default {
  components: {
    NavbarCommon
  },
  data () {
    return {
      back: 'mine',
      title: '五子棋人机对战',
      windowWidth: '', // 实时屏幕宽度
      windowHeight: '', // 实时屏幕高度
      canvasW: '',
      canvasH: '',
      chess: {},
      context: {},
      chessBoard: [], // 记录是否走过
      me: true,
      count: 0, // 所有赢法数量
      wins: [], // 赢法数组
      myWin: [], // 我方赢法的统计数组
      computerWin: [], // 计算机赢法的统计数组
      over: false,
      Num: '',
      victory: ''
    }
  },
  mounted () {
    this.windowWidth = document.documentElement.clientWidth // 实时屏幕宽度
    this.windowHeight = document.documentElement.clientHeight // 实时屏幕高度

    this.Num = Number(Number(this.windowWidth / 30).toFixed())
    console.log(!(this.Num % 2))
    if (!(this.Num % 2)) {
      this.Num = this.Num - 1
    }
    if (this.Num > 13) this.Num = 11
    if (this.Num > 15) this.Num = 15
    console.log(this.Num)

    this.canvasW = this.windowWidth
    this.canvasH = this.windowWidth
    setTimeout(_ => {
      this.init()
    })
  },
  methods: {
    restartInit () {
      this.chess = {}
      this.context = {}
      this.chess = this.$refs.canvas
      this.context = this.chess.getContext('2d')
      this.context.clearRect(0, 0, this.windowWidth, this.windowHeight)
      this.drawChessBoard()
      this.fillArray()
      this.over = false
      this.me = true
      this.init()
    },
    // 初始化
    init () {
      this.chess = this.$refs.canvas
      this.context = this.chess.getContext('2d')
      //   this.drawImage(_ => {
      //     this.drawChessBoard()
      //   })
      this.victory = ''
      this.drawChessBoard()
      this.fillArray()
    },
    // 填充数组
    fillArray () {
      // 是否走过
      for (let i = 0; i < this.Num; i++) {
        this.chessBoard[i] = []
        for (let j = 0; j < this.Num; j++) {
          this.chessBoard[i][j] = 0
        }
      }
      // 赢法数组
      for (let i = 0; i < 15; i++) {
        this.wins[i] = []
        for (let j = 0; j < 15; j++) {
          this.wins[i][j] = []
        }
      }
      // 横
      for (let i = 0; i < 15; i++) {
        for (let j = 0; j < 11; j++) {
          for (let k = 0; k < 5; k++) {
            this.wins[i][j + k][this.count] = true
          }
          this.count++
        }
      }
      // 竖
      for (let i = 0; i < 15; i++) {
        for (let j = 0; j < 11; j++) {
          for (let k = 0; k < 5; k++) {
            this.wins[j + k][i][this.count] = true
          }
          this.count++
        }
      }
      // 斜
      for (let i = 0; i < 11; i++) {
        for (let j = 0; j < 11; j++) {
          for (let k = 0; k < 5; k++) {
            this.wins[i + k][j + k][this.count] = true
          }
          this.count++
        }
      }
      // 反斜
      for (let i = 0; i < 11; i++) {
        for (let j = 14; j > 3; j--) {
          for (let k = 0; k < 5; k++) {
            this.wins[i + k][j - k][this.count] = true
          }
          this.count++
        }
      }
      // 赢法的统计数组
      for (let i = 0; i < this.count; i++) {
        this.myWin[i] = 0
        this.computerWin[i] = 0
      }
    },

    // 绘制棋盘
    drawChessBoard () {
      const { context } = this

      var hh = 30 * (this.Num - 1) + 10

      context.strokeStyle = '#333333'
      for (let i = 0; i < this.Num; i++) {
        context.moveTo(10 + i * 30, 10)
        context.lineTo(10 + i * 30, hh)
        context.stroke()
        context.moveTo(10, 10 + i * 30)
        context.lineTo(hh, 10 + i * 30)
        context.stroke()
      }

      // 画小黑点
      const Pcenter = (Number(this.Num) + 1) / 2 - 1
      var Pleft = Pcenter / 2 - 1
      if (Pleft % 2) {
        Pleft = Pleft + 0.5
        var PRight = Pcenter + Pleft + 1
      } else {
        PRight = Pcenter + Pleft + 2
      }
      context.fillStyle = '#333333'

      context.beginPath()
      context.arc(Pcenter * 30 + 10, Pcenter * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      // 画小黑点
      context.beginPath()
      context.arc(PRight * 30 + 10, PRight * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(Pleft * 30 + 10, Pleft * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(Pleft * 30 + 10, Pcenter * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(PRight * 30 + 10, Pcenter * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(Pcenter * 30 + 10, PRight * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(Pleft * 30 + 10, PRight * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(PRight * 30 + 10, Pleft * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.beginPath()
      context.arc(Pcenter * 30 + 10, Pleft * 30 + 10, 5, 0, 2 * Math.PI)
      context.fill()

      context.closePath()
    },
    // 落子实现
    onStep (x, y, me) {
      const { context } = this
      context.beginPath()
      context.arc(10 + x * 30, 10 + y * 30, 13, 0, 2 * Math.PI)
      context.closePath()
      const gradient = context.createRadialGradient(10 + x * 30 + 2, 10 + y * 30 - 2, 13, 10 + x * 30 + 2, 10 + y * 30 - 2, 0)
      if (me) {
        gradient.addColorStop(0, '#0a0a0a')
        gradient.addColorStop(1, '#636766')
      } else {
        gradient.addColorStop(0, '#d1d1d1')
        gradient.addColorStop(1, '#f9f9f9')
      }
      context.fillStyle = gradient
      context.fill()
      context.closePath()
    },
    // 我方落子
    chessClick (e) {
      if (this.over) {
        return
      }
      if (!this.me) {
        return
      }
      const ox = e.offsetX
      const oy = e.offsetY
      const x = Math.floor(ox / 30)
      const y = Math.floor(oy / 30)
      if (this.chessBoard[x][y] === 0) {
        this.chessBoard[x][y] = 1
        this.onStep(x, y, this.me)
        // 统计赢法
        for (let k = 0; k < this.count; k++) {
          if (this.wins[x][y][k]) {
            this.myWin[k]++
            this.computerWin[k] = 6
            if (this.myWin[k] === 5) {
              //   alert('你赢了')
              this.$msg('您赢了!')
              this.victory = '您赢了！'
              this.over = true
            }
          }
        }
        if (!this.over) {
          this.me = !this.me
          this.computerAI()
        }
      }
    },
    // 计算机落子
    computerAI () {
      const myScore = []
      const computerScore = []
      let max = 0 // 最大分数
      let u = 0; let v = 0 // 所在坐标
      for (let i = 0; i < this.Num; i++) {
        myScore[i] = []
        computerScore[i] = []
        for (let j = 0; j < this.Num; j++) {
          myScore[i][j] = 0
          computerScore[i][j] = 0
        }
      }
      // 遍历棋盘
      for (let i = 0; i < this.Num; i++) {
        for (let j = 0; j < this.Num; j++) {
          if (this.chessBoard[i][j] === 0) {
            // 遍历所有赢法
            for (let k = 0; k < this.count; k++) {
              if (this.wins[i][j][k]) {
                // 我方
                if (this.myWin[k] === 1) {
                  myScore[i][j] += 200
                } else if (this.myWin[k] === 2) {
                  myScore[i][j] += 400
                } else if (this.myWin[k] === 3) {
                  myScore[i][j] += 2000
                } else if (this.myWin[k] === 4) {
                  myScore[i][j] += 10000
                }
                // 计算机
                if (this.computerWin[k] === 1) {
                  computerScore[i][j] += 220
                } else if (this.computerWin[k] === 2) {
                  computerScore[i][j] += 420
                } else if (this.computerWin[k] === 3) {
                  computerScore[i][j] += 2100
                } else if (this.computerWin[k] === 4) {
                  computerScore[i][j] += 20000
                }
              }
            }
            // 比较分数
            if (myScore[i][j] > max) {
              max = myScore[i][j]
              u = i
              v = j
            } else if (myScore[i][j] === max) {
              if (computerScore[i][j] > computerScore[u][v]) {
                u = i
                v = j
              }
            }
            if (computerScore[i][j] > max) {
              max = computerScore[i][j]
              u = i
              v = j
            } else if (computerScore[i][j] === max) {
              if (myScore[i][j] > myScore[u][v]) {
                u = i
                v = j
              }
            }
          }
        }
      }
      this.onStep(u, v, false)
      this.chessBoard[u][v] = 2
      // 统计赢法
      for (let k = 0; k < this.count; k++) {
        if (this.wins[u][v][k]) {
          this.computerWin[k]++
          this.myWin[k] = 6
          if (this.computerWin[k] === 5) {
            // alert('计算机赢了')
            this.$msg('计算机赢了')
            this.victory = '计算机赢了！'
            this.over = true
          }
        }
      }
      if (!this.over) {
        this.me = !this.me
      }
    }
  },
  head () {
    return {
      meta: [
        { hid: 'keywords', name: 'keywords', content: 'AI五子棋' },
        { hid: 'description', name: 'description', content: 'AI五子棋' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  color: white;
  padding-top: 2.778vw;
  line-height: 8.333vw;
  height: 8.333vw;
  font-size: 8.333vw;
}
.Fbuttons {
  margin-bottom: 1rem;
}
.restart {
  background: rgb(233, 197, 153);
  padding: 1.667vw 2.778vw;
  border-radius: 1.667vw;
  font-size: 3.333vw;
  cursor: pointer;
}
.box {
  //   background-color: rgb(212, 208, 202);
  width: 100%;
  height: auto;
  canvas {
    width: 99%;
    border: 0.278vw solid #ddd;
    margin: 5.556vw auto;
    background: rgb(233, 197, 153);
    box-shadow: -0.556vw 0 0.556vw #efefef, 1.389vw 1.389vw 1.389vw #b9b9b9;
  }
}
.win {
  font-size: 5.556vw;
  color: blue;
  padding: 1.389vw;
}
</style>
