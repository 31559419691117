<template>
  <div id="content" :style="backgroundDiv">
    <Game></Game>
  </div>
</template>

<script>
import Game from '@/components/game/Game'
// import Systeminfo from '@/components/common/Systeminfo'
export default {
  components: {
    Game
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  data () {
    return {
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/quanzi.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      height: ''
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
</style>
